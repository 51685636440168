
<template>
  <div class="search-bar-wrapper">
    <div class="search-bar">
      <div class="search-bar-row">
        <div>
          <label for="search-bar-location" class="search-bar__label">
            <LocationIcon />
            <span>Location</span>
          </label>

          <GeoInput
            v-model="geo"
            id="search-bar-location"
            class="search-bar__input"
          />
        </div>

        <div>
          <label for="search-bar-category" class="search-bar__label">
            <RentalTypeIcon />
            <span>Rental type</span>
          </label>

          <CategorySelect
            v-model="selectedCategories"
            id="search-bar-category"
          />
        </div>

        <div>
          <label for="search-bar-date" class="search-bar__label">
            <DateIcon />
            <span>Date</span>
          </label>

          <DateSelect
            v-model="selectedDate"
            id="search-bar-date"
            class="search-bar__input"
          />
        </div>

        <div>
          <PrimaryButton full @click="search">Search</PrimaryButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import GeoInput from '@/components/shared/GeoInput.vue'
import CategorySelect from '@/components/shared/CategorySelect.vue'
import DateSelect from '@/components/shared/DateSelect.vue'
import PrimaryButton from '@/components/shared/buttons/PrimaryButton.vue'
import LocationIcon from '@/components/icons/LocationIcon.vue'
import RentalTypeIcon from '@/components/icons/RentalTypeIcon.vue'
import DateIcon from '@/components/icons/DateIcon.vue'

const geo = ref<string>('')
const selectedCategories = ref<string[]>([])
const selectedDate = ref<string>('')

const search = () => {
  navigateTo({
    name: 'search',
    query: {
      geo: geo.value,
      categories: selectedCategories.value.join(','),
      date: selectedDate.value,
    },
  })
}
</script>

<style lang="scss" scoped>
.search-bar-wrapper {
  padding: 0 80px;
}

.search-bar {
  position: relative;
  background: #fcfffe;
  border: 1px solid #3c464d;
  border-radius: 14px;
  padding: 40px 60px;
  max-width: 1400px;
  margin: -115px auto 0;
  z-index: 3;
}

.search-bar-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-end;
  gap: 0 40px;
}

.search-bar__label {
  display: flex;
  align-items: center;
  gap: 0 10px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0.28px;
  color: #3c464d;
  margin-bottom: 10px;
}

:deep(.v-field) {
  border-radius: 10px;
}

@media screen and (max-width: 1439px) {
  .search-bar {
    padding: 40px;
  }

  .search-bar-row {
    gap: 20px;
  }
}

@media screen and (max-width: 1239px) {
  .search-bar-wrapper {
    padding: 0 10px;
  }

  .search-bar {
    padding: 20px;
    margin-top: -60px;
  }

  .search-bar-row {
    gap: 12px;
  }
}

@media screen and (max-width: 899px) {
  .search-bar-wrapper {
    padding: 0 40px;
  }

  .search-bar {
    margin-top: -160px;
  }

  .search-bar-row {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 599px) {
  .search-bar-wrapper {
    padding: 0 10px;
  }

  .search-bar {
    margin-top: -100px;
  }
}
</style>
