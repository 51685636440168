
<template>
  <div class="categories">
    <div v-for="category in categories" :key="category.title" class="category">
      <NuxtLink :to="category.link">
        <v-img
          class="category__image"
          :aspect-ratio="1"
          :src="category.image"
          cover
        ></v-img>
      </NuxtLink>

      <NuxtLink :to="category.link" class="category__title">
        {{ category.title }}
      </NuxtLink>
    </div>
  </div>
</template>
<script lang="ts" setup>
const categories = [
  {
    title: 'Fishing',
    image: '/img/fishing.jpg',
    link: '#',
  },
  {
    title: 'Boating',
    image: '/img/boating.jpg',
    link: '#',
  },
  {
    title: 'ATV',
    image: '/img/atv.jpg',
    link: '#',
  },
  {
    title: 'Kayaking',
    image: '/img/kayaking.jpg',
    link: '#',
  },
]
</script>


<style lang="scss" scoped>
.categories {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0 40px;
}

.category__image {
  border-radius: 14px;
  margin-bottom: 20px;
}

.category__title {
  display: block;
  font-size: 30px;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0.6px;
  text-align: center;
  color: #3c464d;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

@media screen and (max-width: 1439px) {
  .categories {
    gap: 20px;
  }

  .category__title {
    font-size: 24px;
  }
}

@media screen and (max-width: 899px) {
  .categories {
    grid-template-columns: repeat(2, 1fr);
  }

  .category__title {
    font-size: 20px;
  }

  .category__image {
    margin-bottom: 10px;
  }
}
</style>
