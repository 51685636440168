<template>
  <section>
    <MainBanner />
    <SearchBar />
  </section>

  <section v-if="showRentals">
    <div class="d-flex justify-space-between align-center mb-5">
      <h2 class="section-title">Our rentals</h2>

      <div class="more-button">
        <NuxtLink to="/search">
          <SecondaryButton>More</SecondaryButton>
        </NuxtLink>
      </div>
    </div>

    <RentalCategories />
  </section>

  <section>
    <ActionBanner />
  </section>

  <section v-if="showRecommendations">
    <div class="d-flex flex-wrap justify-space-between align-center mb-5 gap-2">
      <h2 class="section-title">Rentals recommendations</h2>

      <div class="more-button">
        <NuxtLink to="/search">
          <SecondaryButton>More</SecondaryButton>
        </NuxtLink>
      </div>
    </div>

    <RentalRecomendations />
  </section>
</template>

<script setup>
import MainBanner from '@/components/home/MainBanner.vue'
import RentalCategories from '@/components/home/RentalCategories.vue'
import ActionBanner from '@/components/home/ActionBanner.vue'
import RentalRecomendations from '@/components/home/RentalRecomendations.vue'
import SearchBar from '@/components/home/SearchBar.vue'
import SecondaryButton from '@/components/shared/buttons/SecondaryButton.vue'

let showRentals = ref(false);
let showRecommendations = ref(false);
</script>
<style lang="scss" scoped>
section {
  margin-bottom: 60px;
}

.section-title {
  font-size: 30px;
  line-height: 1.1;
  font-weight: 600;
  letter-spacing: 0.6px;
  color: #3c464d;
}

@media screen and (max-width: 899px) {
  .section-title {
    font-size: 24px;
  }

  .more-button {
    display: none;
  }
}
</style>
